import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import BlogContentBody from "../../components/pages/BlogDetails/BlogContentBody"

const FacingChallengesInYourBusiness = () => {
  return (
    <Layout>
      <Seo
        title="Top Tips To Overcome Business Challenges"
        description={`Struggling with business challenges? Discover strategies to overcome obstacles and enhance your success. Get insights and solutions to boost your business performance.`}
      />

      {/* This slug is matched with blogs in Content */}
      <BlogContentBody slug={`facing-challenges-in-your-business`} />
    </Layout>
  )
}

export default FacingChallengesInYourBusiness
